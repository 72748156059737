
export const theme = {
    colors: {
        primary: '#F6CB1A',
        primaryDark: '#76680A',
        secondary: '#FFFFFF',
        tertiary: '#F5F5F5',
        background: '#F6CB1A',
        black: '#000000',
        white: '#FFFFFF',
    },
    text: {
        primary: '#000000',
        secondary: '#FFFFFF',
        tertiary: '#F5F5F5',
    }
}