import { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import { useNavigate } from 'react-router'
import { FiCreditCard, FiLoader, FiSettings } from 'react-icons/fi'
import { Transaction } from '@feddi/sdk-core/dist/db_types'
import { useAuth } from '@contexts/authContext'
import { useQueryParams } from '@contexts/queryParamsContext'
import { isAndroid, isIOS } from '@utils'
import { useFeddiApi } from '@contexts/feddiApiContext'
import Layout from '@components/Layout'
import { theme } from '@theme'

interface WalletProps { }

function Wallet(props: WalletProps) {
    const { userInfo, updateUserInfo } = useAuth()
    const api = useFeddiApi()
    const { params, clearParams } = useQueryParams()
    const [qrCode, setQrCode] = useState<string | null>(null)
    const [transactions, setTransactions] = useState<Transaction[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const selectedWallet = userInfo?.wallets?.find(wallet => wallet.communityId === params.communityId)
    const navigate = useNavigate()

    const debouncedRequest = useCallback(
        debounce((walletId: string) => {
            api
                .getWalletQRCode(walletId)
                .then(res => setQrCode(res.data.data.qrCode))
                .then(() => api.getWalletTransactions(walletId))
                .then(res => res.data.data.transactions)
                .then(transactions => setTransactions(transactions))
        }, 250),
        [api]
    )

    const debounceConfirmTopup = useCallback(
        debounce((paymentIntentId: string) => {
            if (isLoading) {
                return
            }

            setIsLoading(true)
            api
                .confirmTopUp({ paymentIntentId })
                .then(() => {
                    clearParams(['payment_intent', 'redirect_status'])
                    navigate('/')
                    // reload transactions
                    api.getWalletTransactions(selectedWallet?.id!)
                        .then(res => res.data.data.transactions)
                        .then(transactions => setTransactions(transactions))
                        .then(() => updateUserInfo())
                })
                .catch((error) => {
                    alert(`Error confirming topup: code ${error.response?.data.error.code}`)
                })
                .finally(() => setIsLoading(false))
        }, 500),
        [api, selectedWallet]
    )

    useEffect(() => {
        if (userInfo && selectedWallet) {
            debouncedRequest(selectedWallet.id!)
        }
    }, [selectedWallet])

    useEffect(() => {
        if (params.payment_intent && params.redirect_status === 'succeeded' && selectedWallet?.id) {
            debounceConfirmTopup(params.payment_intent)
        } else if (params.topupAmount) {
            navigate('/topup')
        }
    }, [params, selectedWallet])

    return (
        <Layout>
            {/* QR Code Container */}
            <div 
                className="p-6 flex justify-center"
            >
                <div className="bg-gray-100 p-4 rounded-lg">
                    {/* Replace with actual QR code component */}
                    <div className="w-48 h-48 bg-gray-200 flex items-center justify-center text-gray-400">
                        { qrCode && <img src={qrCode} alt="QR Code" /> }
                        { !qrCode && <FiLoader className="text-blue-600 animate-spin" /> }
                    </div>
                    <div className="text-center mt-2 text-sm text-gray-600">
                        Scan to make payment
                    </div>
                </div>
            </div>

            {/* Action Buttons */}
            <div className="px-6 pb-6 grid grid-cols-2 gap-4">
                <button
                    className="py-3 px-4 rounded-lg transition-colors flex items-center justify-center"
                    style={{ backgroundColor: theme.colors.primary, color: theme.text.primary }}
                    onClick={() => navigate('/topup')}
                >
                    <FiCreditCard className='mr-2 text-2xl' />
                    <span className='text-xl font-bold'>Top up</span>
                </button>
                <button 
                    className="text-white py-3 px-4 rounded-lg transition-colors flex items-center justify-center"
                    onClick={() => navigate('/settings')}
                    style={{ backgroundColor: theme.colors.black, color: theme.colors.white }}
                >
                    <FiSettings className='mr-2 text-2xl' />
                    <span className='text-xl font-bold'>Settings</span>
                </button>
            </div>

            <div className="px-6 pb-6">
                {
                    isAndroid() && (
                        <div>
                            <img
                                src="/add-to-google-pay/en/Save to Google Pay - English (Dark).svg"
                                alt="Add to Google Wallet"
                                className="w-full"
                            />
                        </div>
                    )
                }
                {
                    isIOS() && (
                        <div className="flex justify-center">
                            <img
                                src="/add-to-apple-wallet/US_UK/RGB/US-UK_Add_to_Apple_Wallet_RGB_101421.svg"
                                alt="Add to Apple Wallet"
                                style={{ height: '52px' }}
                                onClick={async () => {
                                    const response = await fetch(
                                        process.env.REACT_APP_API_URL + '/passkit/download-pass?walletId=' + selectedWallet?.id + '&type=apple',
                                        {
                                            headers: {
                                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                                            }
                                        }
                                    )
                                    const file = await response.blob()
                                    const blob = new Blob([file], { type: 'application/vnd.apple.pkpass' })
                                    const url = window.URL.createObjectURL(blob)
                                    const a = document.createElement('a')
                                    a.href = url
                                    a.download = "pass.pkpass"
                                    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
                                    a.click()
                                    a.remove()  //afterwards we remove the element again
                                }}
                            />
                        </div>
                    )
                }
            </div>

            {/* Recent Transactions */}
            <div className="border-t border-gray-200 px-6 py-4">
                <h2 className="text-lg font-semibold mb-4">Recent Transactions</h2>
                <div className="space-y-3">
                    {
                        transactions.slice(transactions.length - 10).sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).map(transaction => (
                            <div className="flex justify-between items-center">
                                <div>
                                    <div className="font-medium">
                                        {transaction.transactionType}
                                        <small className="text-gray-500 ml-2 mr-2">
                                            {transaction.receiver?.name || transaction.transactionSubtype}
                                        </small>
                                    </div>
                                    <div className="text-sm text-gray-500">
                                        {new Date(transaction.createdAt!).toLocaleDateString() + ' ' + new Date(transaction.createdAt!).toLocaleTimeString()}
                                    </div>
                                </div>
                                {
                                    transaction.transactionType === 'PAYMENT' && (
                                        <div className="text-red-600">- {Intl.NumberFormat('en-US', { style: 'currency', currency: selectedWallet?.token || 'AED' }).format(transaction.amount ?? 0)}</div>
                                    )
                                }
                                {
                                    transaction.transactionType === 'REWARD' && (
                                        <div className="text-purple-600">+ {Intl.NumberFormat('en-US', { style: 'currency', currency: selectedWallet?.token || 'AED' }).format(transaction.amount ?? 0)}</div>
                                    )
                                }
                                {
                                    transaction.transactionType === 'DEPOSIT' && (
                                        <div className="text-green-600">+ {Intl.NumberFormat('en-US', { style: 'currency', currency: selectedWallet?.token || 'AED' }).format(transaction.amount ?? 0)}</div>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Wallet
