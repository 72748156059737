import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router'
import { theme } from '@theme'
import { useAuth } from '@contexts/authContext'
import { useFeddiApi } from '@contexts/feddiApiContext'
import { useQueryParams } from '@contexts/queryParamsContext'

interface RegisterProps { }

function Register(props: RegisterProps) {
    const navigate = useNavigate()
    const { register, isLoading, error, isAuthenticated, userInfo } = useAuth()
    const { params: { inviteCode, name, phoneNumber } } = useQueryParams()
    const [signupForm, setSignupForm] = useState<{
        name?: string
        email?: string
        phoneNumber?: string
        password?: string
        inviteCode?: string
    }>({})
    const api = useFeddiApi()

    useEffect(() => {
        setSignupForm({
            phoneNumber: phoneNumber || undefined,
            inviteCode: inviteCode || undefined,
        })
    }, [name, phoneNumber, inviteCode])

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        try {
            const token = await register(signupForm)
            api.attachAuthToken(token)
        } catch (err) {
            console.error(err)
        }
    }

    if (isAuthenticated && userInfo) {
        return <Navigate to="/" />
    }

    return (
        <div 
            className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8" 
            style={{ backgroundColor: theme.colors.background }}
        >
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="flex justify-center items-center">
                    <img src={'/ibric.png'} alt="Ibric Logo" width={196} height={196} />
                </div>
                <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
                    Create a new account
                </h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="name" className="block text-md font-bold text-gray-700">
                                Full Name
                            </label>
                            <div className="mt-1">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    required
                                    value={signupForm.name}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                    onChange={(e) => setSignupForm({ ...signupForm, name: e.target.value })}
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="email" className="block text-md font-bold text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    value={signupForm.email || ''}
                                    onChange={(e) => setSignupForm({ ...signupForm, email: e.target.value })}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="phoneNumber" className="block text-md font-bold text-gray-700">
                                Phone Number
                            </label>
                            <div className="mt-1">
                                <input
                                    disabled={!!phoneNumber}
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    type="tel"
                                    required
                                    value={signupForm.phoneNumber || ''}
                                    onChange={(e) => setSignupForm({ ...signupForm, phoneNumber: e.target.value })}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-md font-bold text-gray-700">
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    value={signupForm.password || ''}
                                    onChange={(e) => setSignupForm({ ...signupForm, password: e.target.value })}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                        </div>

                        <input
                            type="hidden"
                            name="inviteCode"
                            value={signupForm.inviteCode}
                            disabled
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />

                        {error && (
                            <div className="text-red-600 text-sm">
                                {error}
                            </div>
                        )}

                        <div>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-bold hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                                style={{ backgroundColor: theme.colors.primary, color: theme.text.primary }}
                            >
                                {isLoading ? (
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                ) : null}
                                Create Account
                            </button>
                        </div>
                    </form>

                    <div className="mt-6">
                        <button
                            onClick={() => navigate('/login')}
                            className="w-full text-center text-md font-bold text-blue-700 hover:text-blue-500"
                        >
                            <span className="font-medium text-gray-700 mr-2">
                                Already have an account? 
                            </span>
                            <span className="font-bold">
                                Sign in
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
