import { FiArrowLeft, FiLoader, FiLogOut } from 'react-icons/fi'
import { useAuth } from '@contexts/authContext'
import { useQueryParams } from '@contexts/queryParamsContext'
import { useNavigate } from 'react-router'
import { theme } from '@theme'

export default function Layout({ children, showBackButton = false }: { children: React.ReactNode, showBackButton?: boolean }) {
    const { userInfo, logout, isLoading } = useAuth()
    const { params } = useQueryParams()
    const navigate = useNavigate()
    const selectedWallet = userInfo?.wallets?.find(wallet => wallet.communityId === params.communityId)

    return (
        <div className="min-h-screen p-4" style={{ backgroundColor: theme.colors.background }}>
            <div className="flex justify-center items-center">
                <img
                    src={'/ibric.png'}
                    alt="ibric"
                    width={156}
                    height={156}
                />
            </div>
            <div
                className="max-w-md mx-auto bg-white rounded-xl shadow-lg overflow-hidden border border-4 border-black mt-4"
            >
                {/* Header */}
                <div
                    className="p-6"
                    style={{ backgroundColor: theme.colors.black, color: theme.colors.white }}
                >
                    <div className="flex justify-between items-center">
                        {
                            (window.history.length > 1 && showBackButton) && (
                                <button
                                    onClick={() => navigate(-1)}
                                    className="bg-white text-blue-600 py-2 px-2 mr-2 rounded-full"
                                >
                                    <FiArrowLeft style={{ color: theme.colors.black }} />
                                </button>
                            )
                        }
                        {
                            !showBackButton && (
                                <div className="mx-4" />
                            )
                        }
                        {
                            !isLoading && (
                                <div className="mt-0 text-3xl font-extrabold">
                                    {
                                        Intl
                                            .NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: selectedWallet?.token || 'AED'
                                            })
                                            .format(selectedWallet?.balance ?? 0)
                                    }
                                    <div className="text-blue-100 text-sm font-medium text-center">Available Balance</div>
                                </div>
                            )
                        }
                        {
                            isLoading && (
                                <div className="mt-0 text-3xl font-extrabold">
                                    <div className="text-blue-100 text-sm font-medium text-center">Loading...</div>
                                </div>
                            )
                        }
                        <button
                            className="bg-white text-white py-2 px-2 rounded-full"
                            onClick={logout}
                        >
                            <FiLogOut style={{ color: theme.colors.black }} />
                        </button>
                    </div>
                </div>

                {children}
            </div>
        </div>
    )
}