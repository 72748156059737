import { useAuth } from '@contexts/authContext'
import { useQueryParams } from '@contexts/queryParamsContext'
import Layout from '@components/Layout'
import { FiUser, FiMail, FiPhone } from 'react-icons/fi'

interface SettingsProps {}

function Settings(props: SettingsProps) {
    const { userInfo } = useAuth()
    const { params } = useQueryParams()
    const selectedWallet = userInfo?.wallets?.find(wallet => wallet.communityId === params.communityId)

    return (
        <Layout showBackButton>
            <div className="p-6">
                <h2 className="text-2xl font-bold mb-6">Account Settings</h2>

                <div className="space-y-6">
                    {/* User Info Section */}
                    <div className="bg-gray-50 p-4 rounded-lg">
                        <div className="flex items-center mb-4">
                            <FiUser className="text-blue-600 text-xl mr-3" />
                            <div>
                                <div className="text-sm text-gray-500">Name</div>
                                <div className="font-medium">{userInfo?.name || 'Not set'}</div>
                            </div>
                        </div>

                        <div className="flex items-center mb-4">
                            <FiMail className="text-blue-600 text-xl mr-3" />
                            <div>
                                <div className="text-sm text-gray-500">Email</div>
                                <div className="font-medium">{userInfo?.email || 'Not set'}</div>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <FiPhone className="text-blue-600 text-xl mr-3" />
                            <div>
                                <div className="text-sm text-gray-500">Phone</div>
                                <div className="font-medium">{userInfo?.phoneNumber || 'Not set'}</div>
                            </div>
                        </div>
                    </div>

                    {/* Wallet Info Section */}
                    <div className="bg-gray-50 p-4 rounded-lg">
                        <h3 className="font-semibold mb-3">Wallet Information</h3>
                        <div className="space-y-2">
                            <div>
                                <span className="text-gray-500">Wallet Name: </span>
                                <span className="font-medium">{selectedWallet?.name || 'Not set'}</span>
                            </div>
                            <div>
                                <span className="text-gray-500">Currency: </span>
                                <span className="font-medium">{selectedWallet?.token || 'Not set'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Settings
