import React, { createContext, useContext, useState, useCallback, useEffect } from 'react'
import { User, Wallet } from '@feddi/sdk-core/dist/db_types'
import { useNavigate } from 'react-router'
import { useQueryParams } from './queryParamsContext'

export type UserInfo = User & {
  wallets?: Wallet[]
}

interface AuthContextType {
  isAuthenticated: boolean
  userInfo: UserInfo | null
  login: (email: string, password: string) => Promise<string>
  register: (formData: {
    name?: string
    email?: string
    phoneNumber?: string
    password?: string
    inviteCode?: string
  }) => Promise<string>
  logout: () => void
  updateUserInfo: () => Promise<void>
  isLoading: boolean
  error: string | null
}

const AuthContext = createContext<AuthContextType | null>(null)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('token'))
  const { setParam } = useQueryParams()
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const loadUserInfo = useCallback(async () => {
    setIsLoading(true)
    try {
      const queryParams = new URLSearchParams()
      queryParams.set('include[wallets]', 'true')

      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/me?${queryParams.toString()}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      const data = await response.json()

      if (data.data.user?.wallets?.length > 0) {
        setParam('communityId', data.data.user.wallets[0].communityId)
      }

      return data.data.user
    } catch (err) {
      throw err
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token')
      if (token) {
        setIsLoading(true)
        try {
          const userInfo = await loadUserInfo()
          setUserInfo(userInfo)
          setIsAuthenticated(true)
        } catch (err) {
          // If loading fails, clear the invalid token and go to login page
          localStorage.removeItem('token')
          window.location.href = '/login'
          setError(err instanceof Error ? err.message : 'Authentication failed')
        } finally {
          setIsLoading(false)
        }
      }
    }

    initializeAuth()
  }, [loadUserInfo])

  const login = useCallback(async (email: string, password: string) => {
    setIsLoading(true)
    setError(null)
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Login failed')
      }

      localStorage.setItem('token', data.data.token)
      const userInfo = await loadUserInfo()
      
      setUserInfo(userInfo)
      setIsAuthenticated(true)
      return data.data.token
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Login failed')
      throw err
    } finally {
      setIsLoading(false)
    }
  }, [loadUserInfo])

  const register = useCallback(async (formData: {
    name?: string
    email?: string
    phoneNumber?: string
    password?: string
    inviteCode?: string
  }) => {
    setIsLoading(true)
    setError(null)

    try {
      // first signup as a user
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          password: formData.password,
        }),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Registration failed')
      }

      localStorage.setItem('token', data.data.token)

      // then accept the invite
      if (formData.inviteCode) {
        const inviteResponse = await fetch(`${process.env.REACT_APP_API_URL}/invites/accept`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({ inviteCode: formData.inviteCode }),
        })

        if (!inviteResponse.ok) {
          throw new Error(inviteResponse.statusText || 'Joining wallet program failed')
        }

        const inviteData = await inviteResponse.json()
        localStorage.setItem('communityId', inviteData.data.invite.communityId)
      }

      // finally load the user info and attach it to the state
      const userInfo = await loadUserInfo()

      setUserInfo(userInfo)
      setIsAuthenticated(true)

      return data.data.token
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Registration failed')
      throw err
    } finally {
      setIsLoading(false)
    }
  }, [loadUserInfo])

  const updateUserInfo = useCallback(async () => {
    const userInfo = await loadUserInfo()
    setUserInfo(userInfo)
  }, [loadUserInfo])

  const logout = useCallback(() => {
    localStorage.removeItem('token')
    setUserInfo(null)
    setIsAuthenticated(false)
  }, [])

  return (
    <AuthContext.Provider 
      value={{ 
        isAuthenticated, 
        userInfo, 
        login, 
        register, 
        logout,
        updateUserInfo,
        isLoading, 
        error 
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
