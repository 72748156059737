import React, { createContext, useContext, useState, useEffect } from 'react'

// Add a new type to specify which params should be stored
const STORED_PARAMS = ['communityId', 'walletId', 'referralCode', 'inviteCode', 'topupAmount', 'payment_intent', 'redirect_status'] as const
type StoredParamKey = typeof STORED_PARAMS[number]

type QueryParams = {
    communityId: string | null
    walletId: string | null
    referralCode: string | null
    // for signup
    inviteCode: string | null
    name: string | null
    phoneNumber: string | null
    topupAmount: string | null
    // for stripe redirect
    payment_intent: string | null
    redirect_status: string | null
    [key: string]: string | null
}

interface QueryParamsContextType {
    params: QueryParams
    setParam: (key: keyof QueryParams, value: string | null) => void
    clearParams: (keys: (keyof QueryParams)[]) => void
}

const defaultParams: QueryParams = {
    communityId: null,
    walletId: null,
    referralCode: null,
    payment_intent: null,
    redirect_status: null,
    inviteCode: null,
    name: null,
    phoneNumber: null,
    topupAmount: null,
}

const QueryParamsContext = createContext<QueryParamsContextType | undefined>(undefined)

export function QueryParamsProvider({ children }: { children: React.ReactNode }) {
    const [params, setParams] = useState<QueryParams>(defaultParams)

    useEffect(() => {
        const loadParams = () => {
            const queryParams = new URLSearchParams(window.location.search)
            const newParams: QueryParams = { ...defaultParams }

            Object.keys(defaultParams).forEach((key) => {
                const urlValue = queryParams.get(key)
                if (urlValue !== null) {
                    newParams[key] = urlValue
                    // Only store in localStorage if it's a stored param
                    if (STORED_PARAMS.includes(key as StoredParamKey)) {
                        localStorage.setItem(key, urlValue)
                    }
                } else if (STORED_PARAMS.includes(key as StoredParamKey)) {
                    // Only check localStorage for stored params
                    const storedValue = localStorage.getItem(key)
                    if (storedValue !== null) {
                        newParams[key] = storedValue
                    }
                }
            })

            setParams(newParams)
        }

        // Load params on mount and when URL changes
        loadParams()

        // Optional: Add listener for URL changes
        const handleUrlChange = () => {
            loadParams()
        }
        window.addEventListener('popstate', handleUrlChange)
        return () => window.removeEventListener('popstate', handleUrlChange)
    }, [])

    const setParam = (key: keyof QueryParams, value: string | null) => {
        setParams(prev => {
            const newParams = { ...prev, [key]: value }
            // Only store in localStorage if it's a stored param
            if (STORED_PARAMS.includes(key as StoredParamKey)) {
                if (value !== null) {
                    localStorage.setItem(key.toString(), value)
                } else {
                    localStorage.removeItem(key.toString())
                }
            }
            return newParams
        })
    }

    const clearParams = (keys: (keyof QueryParams)[]) => {
        keys.forEach(key => {
            setParams(prev => ({ ...prev, [key]: null }))
            localStorage.removeItem(key.toString())
        })
    }

    return (
        <QueryParamsContext.Provider value={{ params, setParam, clearParams }}>
            {children}
        </QueryParamsContext.Provider>
    )
}

export function useQueryParams() {
    const context = useContext(QueryParamsContext)
    if (context === undefined) {
        throw new Error('useQueryParams must be used within a QueryParamsProvider')
    }
    return context
} 