import React, { useState } from 'react'
import { useAuth } from '@contexts/authContext'
import { useFeddiApi } from '@contexts/feddiApiContext'
import { Navigate, useNavigate } from 'react-router'
import { theme } from '@theme'
interface LoginProps {}

function Login(props: LoginProps) {
    const navigate = useNavigate()
    const { login, isLoading, error, isAuthenticated, userInfo } = useAuth()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const api = useFeddiApi()

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        try {
            const token = await login(email, password)
            api.attachAuthToken(token)
        } catch (err) {
            console.error(err)
        }
    }

    const renderLoginForm = () => {
        return (
            <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="email" className="block text-md font-extrabold text-gray-700">
                        Email address
                    </label>
                    <div className="mt-1">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="password" className="block text-md font-extrabold text-gray-700">
                        Password
                    </label>
                    <div className="mt-1">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                </div>

                {error && (
                    <div className="text-red-600 text-sm">
                        {error}
                    </div>
                )}

                <div>
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                        style={{ backgroundColor: theme.colors.primary, color: theme.text.primary }}
                    >
                        {isLoading ? (
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        ) : null}
                        Sign in
                    </button>
                </div>
            </form>
        )
    }

    if (isAuthenticated && userInfo) {
        return <Navigate to="/" />
    }

    return (
        <div 
            className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8"
            style={{ backgroundColor: theme.colors.background }}
        >
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="flex justify-center items-center">
                    <img src={'/ibric.png'} alt="Ibric Logo" width={196} height={196} />
                </div>
                <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
                    Sign in to your account
                </h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    {renderLoginForm()}

                    <div className="mt-6">
                        <button
                            onClick={() => navigate('/register')}
                            className="w-full text-center text-md font-bold text-blue-700 hover:text-blue-500"
                        >
                            <span className="font-medium text-gray-700 mr-2">
                                Need an account? 
                            </span>
                            <span className="font-bold">
                                Sign up
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
