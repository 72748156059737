import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'
import { useFeddiApi } from '@contexts/feddiApiContext'
import { useAuth } from '@contexts/authContext'
import { useQueryParams } from '@contexts/queryParamsContext'
import Layout from '@components/Layout'
import TopupForm from '@components/TopupForm'
import { theme } from '@theme'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? '')

export default function Topup() {
    const api = useFeddiApi()
    const { userInfo } = useAuth()
    const { params, clearParams } = useQueryParams()
    const selectedWallet = userInfo?.wallets?.find(wallet => wallet.communityId === params.communityId)
    const [paymentIntent, setPaymentIntent] = useState<{
        id: string
        clientSecret: string
        customer: string
        paymentIntent: string
        publishableKey: string
    } | null>(null)
    const [amount, setAmount] = useState(10)

    const debouncedCreateOrUpdatePaymentIntent = useCallback(
        debounce(async (amount: number, walletId?: string, paymentIntentId?: string) => {
            if (!walletId) {
                return
            }

            if (!paymentIntentId) {
                await api
                    .requestTopUp({
                        amount: amount,
                        walletId: walletId
                    })
                    .then(res => setPaymentIntent(res.data.data.paymentIntent))
            } else {
                await api
                    .updatePaymentIntentAmount({
                        amount,
                        paymentIntentId
                    })
            }
        }, 500),
        []
    )

    useEffect(() => {
        debouncedCreateOrUpdatePaymentIntent(amount, selectedWallet?.id, paymentIntent?.id)
    }, [amount])

    useEffect(() => {
        if (params.topupAmount) {
            setAmount(parseInt(params.topupAmount))
            clearParams(['topupAmount'])
        }
    }, [params.topupAmount])

    return (
        <Layout showBackButton={true}>
            {
                !paymentIntent && (
                    <div className="p-6 flex flex-col items-center justify-center">
                        <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
                        <p className="mt-4 text-gray-600">Preparing payment form...</p>
                    </div>
                )
            }
            {
                paymentIntent && (
                    <>
                        <div className="p-4">
                            {/* <label className="block text-md font-bold text-gray-700 mb-2 text-center">
                                Amount to Top Up
                            </label> */}
                            <div className="grid grid-cols-1 gap-2">
                                {
                                    [
                                        { amount: 100, cashback: 10, cashbackPercentage: 10 }, 
                                        { amount: 250, cashback: 50, cashbackPercentage: 20 }, 
                                        { amount: 500, cashback: 150, cashbackPercentage: 30 }
                                    ].map(({ amount: amountChoice, cashback, cashbackPercentage }) => (
                                        <button
                                            onClick={() => setAmount(amountChoice)}
                                            className={'px-4 py-5 rounded-md border border-black font-bold text-md'}
                                            style={{ 
                                                borderColor: amount === amountChoice ? theme.colors.primary : 'lightgray',
                                                fontWeight: amount === amountChoice ? 'bold' : 'normal',
                                                backgroundColor: amount === amountChoice ? '#F9F1B8' : 'white'
                                            }}
                                        >
                                            <span className="text-md mx-1 font-light">Top-up</span> 
                                            AED { amountChoice },
                                            <span className="text-md mx-1 font-light">get</span>
                                            AED { cashback }
                                            <span className="text-md mx-1 font-light">Extra</span>
                                        </button>
                                    ))
                                }
                            </div>
                        </div>
                        <Elements
                            stripe={stripePromise}
                            options={{
                                clientSecret: paymentIntent?.clientSecret,
                            }}
                        >
                            <TopupForm />
                        </Elements>
                    </>
                )
            }
        </Layout>
    )
}