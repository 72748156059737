import { Helmet } from 'react-helmet-async'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router'
import './App.css'
import Wallet from '@pages/Wallet'
import Login from '@pages/Login'
import Register from '@pages/Register'
import { useAuth } from '@contexts/authContext'
import Topup from '@pages/Topup'
import { useQueryParams } from '@contexts/queryParamsContext'
import Settings from '@pages/Settings'

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuth()
  
  if (!isAuthenticated) {
    // user is not authenticated
    return <Navigate to="/login" />
  }

  return children;
}

function App() {
  useQueryParams()
  
  const renderContent = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={
            <ProtectedRoute>
              <Wallet />
            </ProtectedRoute>
          } />
          <Route path="/topup" element={
            <ProtectedRoute>
              <Topup />
            </ProtectedRoute>
          } />
          <Route path="/settings" element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    )
  }

  return (
    <>
      <Helmet>
        <title>Feddi Wallet</title>
        <meta name="description" content="Feddi integrated wallet" />
        <meta name="author" content="Feddi" />
        <meta name="keywords" content="Feddi, wallet, payment, crypto" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
      </Helmet>
      {renderContent()}
    </>
  )
}

export default App
